<template>
	<div class="admin-item-row clickable sessions-row">
		<div class="admin-item-col small" @click="$emit('on-click', whitelistItem.id)">
			{{ parseInt(whitelistItem.id) }}
		</div>
		<div class="admin-item-col medium" @click="$emit('on-click', whitelistItem.id)">
			{{ whitelistItem.value }}
		</div>
		
		<div class="admin-item-col medium actions">
			<button class="button red" @click="onRemove(whitelistItem.value)" title="Remove">Remove</button>
		</div>
	</div>
</template>

<script>
import { time, diffInTime } from '@/helpers'
import { mapState } from 'vuex';
export default {
	name: 'WhitelistResult',
	components: {},
	props: {
		whitelistItem: {
			type: Object,
			default: () => ({}),
		},
		cancelSession: {
			type: Function,
		},
		onRemove: {
			type: Function,
		},
	},
	data() {
		return {}
	},

	computed: {
		...mapState({
			serverTime: (state) => state.serverTime,
		}),
	},
	methods: {
		time,
		diffInTime,
		getDuration(sessionData) {
			const entryQr = sessionData.sessionQRCodes.find(qr => qr.qrType == 'ENT')
			if (entryQr && entryQr.used == entryQr.usage) {
				return this.diffInTime(sessionData.startTime, (sessionData.endTime || this.serverTime))
			}
			return this.diffInTime(sessionData.startTime, this.serverTime)
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
	&.sessions-row{
		.button{
			line-height: 34px;
		}
	}
}

.admin-item-row {
	&.sessions-row {
		.button {
			line-height: 34px;
		}
	}
}
</style>
