<template>
	<div class="admin-menu">
		<div class="admin-menu-row">
			<router-link :class="{ active: activeMenu === 'admin' }" class="admin-menu-link" to="/admin">
				Admin Panel
			</router-link>
			<router-link
				:class="{ active: activeMenu === 'attribution' }"
				class="admin-menu-link"
				to="/attribution"
			>
				Attribution Panel
				<!-- <span class="admin-navigation-button-number">3</span> -->
			</router-link>
			<router-link
				:class="{ active: activeMenu === 'parking' }"
				class="admin-menu-link"
				to="/adminParking"
			>
				Parking Panel
				<!-- <span class="admin-navigation-button-number">3</span> -->
			</router-link>
			<router-link
				:class="{ active: activeMenu === 'session' }"
				class="admin-menu-link"
				to="/adminSession"
			>
				Session Panel
			</router-link>
		</div>
		<div class="admin-menu-row">
			<router-link
				:class="{ active: activeMenu === 'export' }"
				class="admin-menu-link"
				to="/adminExport"
			>
				Data Export Panel
				<!-- <span class="admin-navigation-button-number">3</span> -->
			</router-link>
			<router-link
				:class="{ active: activeMenu === 'municipality' }"
				class="admin-menu-link"
				to="/municipality"
			>
				Municipality Panel
				<!-- <span class="admin-navigation-button-number">3</span> -->
			</router-link>
			<router-link
				:class="{ active: activeMenu === 'coupon' }"
				class="admin-menu-link"
				to="/coupon"
			>
				Coupon Panel
				<!-- <span class="admin-navigation-button-number">3</span> -->
			</router-link>
			<router-link
				:class="{ active: activeMenu === 'whitelist' }"
				class="admin-menu-link"
				to="/whitelist"
			>
			Whitelist
				<!-- <span class="admin-navigation-button-number">3</span> -->
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AdminMenu',
	components: {},
	props: {
		activeMenu: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {},
	created() {},

	methods: {},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/adminMenu';
</style>
