var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-blue"},[(!_vm.sessionStop || _vm.isLoading)?_c('Loading'):_vm._e(),(_vm.sessionStop && !_vm.isLoading)?_c('div',{staticClass:"start-session-qr"},[_c('ConnectionStatus',{attrs:{"getSessionById":_vm.loadSessionPeriodically}}),_c('GenerateQr',{attrs:{"type":_vm.localizedData.scanType,"title":_vm.localizedData.title,"description":_vm.localizedData.description,"sessionId":_vm.sessionStop ? _vm.sessionStop.sessionId : '0',"qrCodeText":_vm.sessionStop && _vm.sessionStop.sessionQRCodes.length > 0
					? _vm.sessionStop.sessionQRCodes[_vm.sessionStop.sessionQRCodes.length - 1].qrCode
					: 'scan to encode',"numOfScan":_vm.sessionStop
					? _vm.sessionStop.sessionQRCodes.length > 0
						? _vm.sessionStop.sessionQRCodes[_vm.sessionStop.sessionQRCodes.length - 1].used
						: 0
					: 0,"totalScan":_vm.sessionStop
					? _vm.sessionStop.sessionQRCodes.length > 0
						? _vm.sessionStop.sessionQRCodes[_vm.sessionStop.sessionQRCodes.length - 1].usage
						: 0
					: 0,"prevNumOfScan":_vm.prevNumOfScan,"sessionQrData":_vm.sessionStop
					? _vm.sessionStop.sessionQRCodes.length > 0
						? _vm.sessionStop.sessionQRCodes
						: []
					: [],"message":_vm.localizedData.message,"timeLimit":_vm.sessionStop
					? _vm.sessionStop.sessionQRCodes.length > 0
						? _vm.sessionStop.sessionQRCodes[_vm.sessionStop.sessionQRCodes.length - 1].expiresIn
						: 5
					: 5,"startTime":_vm.sessionStop ? _vm.sessionStop.sessionQRCodes[_vm.sessionStop.sessionQRCodes.length - 1].usedAt : 0,"endSession":_vm.openModal}})],1):_vm._e(),_c('SessionModal',{attrs:{"showModal":_vm.showModal},on:{"close":_vm.closeModal,"confirm":_vm.confirmAction},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',[_vm._v(_vm._s(_vm.modalTitle))])]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.modalDescription)+" ")]),_c('div',[_c('div',{staticClass:"alert-message"},[_c('img',{staticClass:"icon-svg alert-img",attrs:{"src":require("../../assets//images/session//icon_warning_black.svg"),"alt":"Add Circle Icon"}}),_c('span',{staticClass:"alert"},[_vm._v(_vm._s(_vm.modalAlertText))])])])]},proxy:true},{key:"confirmButton",fn:function(){return [_c('button',{on:{"click":_vm.confirmAction}},[_vm._v("Recuperer mon velo")])]},proxy:true},{key:"cancelButton",fn:function(){return [_c('a',{staticClass:"cancel",on:{"click":_vm.closeModal}},[_vm._v("Annuler")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }