// whitelist.js

import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import { findIndex, pick, omit } from 'lodash/fp'
import Vue from 'vue'
import i18n from '@/i18nVeeValidate'

// Initial state
const initialErrorState = ''
const initialState = () => ({
	all: [],
	total: 0,
	error: '',
	loading: false,
})

export const whitelistProps = `
		id
		type
		value
		createdAt
		updatedAt
`

const state = initialState()

const actions = {
	async getWhitelistEmails({ commit }, { limit, offset, isActive, search }) {
		try {
			commit('setLoading', true)

			const response = await graphqlClient.query({
				query: gql`
					query allWhitelist($limit: Int, $offset: Int, $type: String, $search: String) {
						allWhitelist(limit: $limit, offset: $offset, type: $type, search: $search) {
							${whitelistProps}
						}
					}
				`,
				variables: { limit, offset, isActive, search },
				fetchPolicy: 'no-cache',
			})
			const allWhitelist = response.data.allWhitelist || []

			commit('setWhitelist', allWhitelist)
		} catch (e) {
			logger.error('Problem retrieving Whitelist', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async addWhitelistEmail({ commit, dispatch }, email) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation whitelistAddEmail($email: String!) {
						whitelistAddEmail(email: $email) {
							${whitelistProps}
						}
					}
				`,
				variables: {
					email,
				},
			})

			if (response.data.whitelistAddEmail) {
				const error = response.data.whitelistAddEmail.error

				if (error) {
					if (error.generic) {
						dispatch('alert/error', error.generic, {
							root: true,
						})
					} else {
						dispatch('alert/error', 'Validation error. Please check you inputs', {
							root: true,
						})
						commit('setWhitelistError', error)
					}
				} else {
					commit('createWhitelistItem', response.data.whitelistAddEmail)
					dispatch('alert/success', i18n.t('whitelist email added successfully'), { root: true })

					return response.data.whitelistAddEmail
				}
			}
		} catch (e) {
			logger.error('whitelist add item error', e)

			// dispatch('alert/error', e.message, { root: true })
			dispatch('alert/error', 'Failed to store, please check you input.', {
				root: true,
			})
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},

	async removeWhitelistEmail({ commit, dispatch }, email) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation whitelistRemoveEmail($email: String!) {
						whitelistRemoveEmail(email: $email) {
							${whitelistProps}
						}
					}
				`,
				variables: {
					email,
				},
			})

			if (response.data.whitelistRemoveEmail) {
				dispatch('alert/success', i18n.t('whitelist email removed successfully'), { root: true })
				return true
			}
		} catch (e) {
			logger.error('whitelist add item error', e)

			// dispatch('alert/error', e.message, { root: true })
			dispatch('alert/error', 'Failed to store, please check you input.', {
				root: true,
			})
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},
}

const mutations = {
	setLoading(state, loadingState) {
		state.loading = loadingState
	},

	setWhitelist(state, whitelist) {
		state.all = whitelist
	},

	createWhitelistItem(state, whitelist) {
		state.all.push(Object.assign({}, whitelist))
	},
	setWhitelistError(state, error) {
		state.error = error
	},
	resetWhitelistError(state) {
		state.error = ''
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
