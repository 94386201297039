<template>
	<div class="admin-report-parameter">
		<div class="admin-item-info-field">
			<a href="#" class="link" @click="toggleReportParameters">
				{{ showReportParameters ? 'hide' : 'show' }} parameters
			</a>
		</div>
		<div v-if="showReportParameters">
			<div class="admin-item-info-field">
				<p style="margin-right: 5px" class="admin-item-info-text">
					<strong>{{ $t('admin.reportParameterFromDate') }}:</strong>
				</p>
				<date-picker
					:clearable="false"
					:value="value.from"
					class="admin-item-col small"
					lang="en"
					format="DD-MM-YYYY"
					confirm
					confirmText="Update"
					inputClass="input-field small"
					@input="paramChange($event, 'from')"
				/>
			</div>

			<div class="admin-item-info-field">
				<p style="margin-right: 5px" class="admin-item-info-text">
					<strong>{{ $t('admin.reportParameterToDate') }}:</strong>
				</p>
				<date-picker
					:value="value.to"
					:clearable="false"
					class="admin-item-col small"
					lang="en"
					format="DD-MM-YYYY"
					confirm
					confirmText="Update"
					inputClass="input-field small"
					@input="paramChange($event, 'to')"
				/>
			</div>
			<div class="admin-item-info-field" v-if="showGroupOf">
				<p class="admin-item-info-text">
					<strong>{{ $t('admin.reportParameterGroupOf') }}:</strong>
				</p>
				<input
					:value="value.groupOf"
					type="number"
					class="input-field small"
					name="price"
					@input="paramChange($event, 'groupOf')"
				/>
			</div>
			<div class="admin-item-info-field" v-if="showGroupBy">
				<p class="admin-item-info-text">
					<strong>{{ $t('admin.reportParameterGroupBy') }}:</strong>
				</p>
				<select
					:value="value.groupBy"
					class="admin-item-info-select"
					@input="paramChange($event, 'groupBy')"
				>
					<option v-for="option in groupOptions" :key="option.value" :value="option.value">
						{{ option.text }}
					</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { REPORT_FILTER_GROUPS } from '@/config'
import { keys, map } from 'lodash/fp'
import { logger } from '@/logger'

export default {
	name: 'AdminReportFilter',
	components: {
		DatePicker,
	},
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
		showGroupBy: {
			type: Boolean,
			default: true,
		},
		showGroupOf: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			groupOptions: map(
				(k) => ({ value: k, text: REPORT_FILTER_GROUPS[k] }),
				keys(REPORT_FILTER_GROUPS)
			),
			showReportParameters: false,
		}
	},
	computed: {},
	watch: {
		value(newValue) {
			if (Object.keys(this.item).length === 0 && this.item.constructor === Object) {
				this.item = newValue
			}
		},
	},
	created() {
		this.item = { ...this.value }
	},

	methods: {
		toggleReportParameters(e) {
			e.preventDefault()

			this.showReportParameters = !this.showReportParameters
		},

		paramChange(value, key) {
			logger.debug('paramChange', value, this.item)
			this.item[key] = value
			this.$emit('input', this.item)
		},
	},
}
</script>
