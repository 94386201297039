<template>
	<div>
		<div class="admin-parking-info-header">
			<h2 class="admin-parking-info-header-title">
				Session {{ sessionDetail.sessionId }}
			</h2>
			<div>
				<button v-if="sessionDetail.active" class="button admin-parking-info-header-button red"
					@click="cancelSession(sessionDetail.id)">
					Cancel Session
				</button>
				<button v-if="sessionDetail.active" class="button admin-parking-info-header-button red"
					style="margin-left: 30px;"
					@click="onSessionClose(sessionDetail.id)">
					Close Session
				</button>
				<button class="button blue admin-parking-info-header-button" style="margin-left: 30px;"
					@click="getSessionById(sessionDetail.id)">
					Refresh
				</button>
				<button class="button blue admin-parking-info-header-button" @click="close()">
					Go Back
				</button>
			</div>
		</div>
		<div v-if="sessionDetail.id">
			<div class="">
				<h3 class="admin-parking-info-header-title">User</h3>
				<div v-if="sessionDetail.user">
					<div class="admin-item-info-row">
						<div>
							<strong>Name:</strong> {{ sessionDetail.user.lastName }} {{ sessionDetail.user.firstName }}
							(id:
							{{
							sessionDetail.user.id }})
						</div>
						<div><strong>Email: </strong> {{ sessionDetail.user.email }}</div>
						<div><strong>Language: </strong> {{ sessionDetail.user.language }}</div>
					</div>

					<div class="admin-item-info-row">
						<div><strong>Mobib: </strong> {{ sessionDetail.user.mobib || 'not defined' }}</div>
						<div><strong>Bank account: </strong> {{ sessionDetail.user.bankAccount || 'not defined' }}</div>
						<div><strong>Newsletter: </strong> {{ sessionDetail.user.newsletter ? 'Yes' : 'no' }}</div>
					</div>
					<div>
						<div v-for="(address, index) in sessionDetail.user.addresses" :key="index">
							<strong>{{ address.addressType }}: </strong>
							{{ structuredAddress(address) }}
							<div v-if="address.realAddress" class="real-address">
								<!-- <span>&rarr; </span> -->
								<span>&#10149; </span>
								<strong>Correct Address: </strong>
								{{ address.realAddress }}
							</div>
						</div>
						<div v-if="sessionDetail.user.legacyAddress"><strong>Legacy: </strong>{{
							sessionDetail.user.legacyAddress }}
						</div>
					</div>
					<hr />
				</div>
			</div>

			<!-- session information -->
			<div class="">
				<h3 class="admin-parking-info-header-title">Session</h3>
				<div>
					<div class="admin-item-info-row">
						<div>
							<strong>Session Id:</strong> {{ sessionDetail.id }}
						</div>
						<div><strong>Status: </strong> {{ sessionDetail.active ? 'Active' : 'Not Active' }}</div>
						<div><strong>Bikes: </strong> {{ sessionDetail.bikes }}</div>
					</div>
					<div class="admin-item-info-row">
						<div><strong>Parking: </strong> {{ sessionDetail.parking.code }}</div>
						<div><strong>Start Time: </strong> {{ time(sessionDetail.startTime) }}</div>
						<div><strong>Duration: </strong> {{ getDuration(session) }}</div>
					</div>
					<div class="admin-item-info-row">
						<div><strong>Payment Method: </strong> {{ sessionDetail.paymentMethod }}</div>
						<div><strong>Amount Paid: </strong> €{{ sessionDetail.totalAmountPaid }}</div>
					</div>
				</div>

				<!-- Session Payments -->
				<h3 class="admin-parking-info-header-title" style="margin-top: 30px;">Payments</h3>
				<div class="qr-codes">
					<section v-if="sessionDetail.sessionPayments && sessionDetail.sessionPayments.length > 0">
						<div class="admin-item-row" style="border-color: black">
							<div class="admin-item-col smaller header">ID</div>
							<div class="admin-item-col medium header">No of Hours</div>
							<div class="admin-item-col medium header">Amount Paid</div>
							<div class="admin-item-col medium header">Transaction Id</div>
							<div class="admin-item-col smaller header">Created</div>
							<div class="admin-item-col smaller header">Paid At</div>
							<div class="admin-item-col large header"></div>
						</div>
						<div v-for="(payment, index) in sessionDetail.sessionPayments" :key="index"
							class="admin-item-row clickable">
							<div class="admin-item-col smaller">
								{{ parseInt(payment.id) }}
							</div>
							<div class="admin-item-col medium">
								{{ payment.hours }}
							</div>
							<div class="admin-item-col medium">
								€{{ payment.amountPaid }}
							</div>
							<div class="admin-item-col medium">
								{{ payment.transactionId }}
							</div>
							<div class="admin-item-col smaller">
								{{ date(payment.createdAt) }} <br /> {{ time(payment.createdAt) }}
							</div>
							<div class="admin-item-col smaller">
								<span v-if="payment.paidAt">{{ date(payment.paidAt) }} <br /> {{ time(payment.paidAt) }}</span>
								<span v-else>-</span>
							</div>
							<div class="admin-item-col large">
								<a v-if="payment.paidAt" :href="`${invoiceUrl}?paymentId=${payment.id}`">
								<button class="button blue tiny subscription-deposit-action">Invoice</button>
							</a>
								<button v-if="!payment.paidAt" class="button blue tiny subscription-deposit-action" @click="resendPaymentLinkSession(payment.id)">P-link</button>
							</div>
						</div>
					</section>
					<section v-else style="text-align:center"> No payment yet</section>
				</div>

				<!-- session QR codes generated untill now-->
				<h3 class="admin-parking-info-header-title">QR Codes</h3>
				<div class="qr-codes">
					<section v-if="sessionDetail.sessionQRCodes.length > 0">
						<div class="admin-item-row" style="border-color: black">
							<div class="admin-item-col smaller header">ID</div>
							<div class="admin-item-col tiny header">QR Type</div>
							<div class="admin-item-col tiny header">Usage</div>
							<div class="admin-item-col tiny header">Used</div>
							<div class="admin-item-col smaller header">Created</div>
							<div class="admin-item-col smaller header">Expiry</div>
							<div class="admin-item-col large header">QR Code</div>
						</div>
						<div v-for="(qr, index) in sessionDetail.sessionQRCodes" :key="index"
							class="admin-item-row clickable">
							<div class="admin-item-col smaller">
								{{ parseInt(qr.id) }}
							</div>
							<div class="admin-item-col tiny">
								{{ qr.qrType }}
							</div>
							<div class="admin-item-col tiny">
								{{ qr.usage }}
							</div>
							<div class="admin-item-col tiny">
								{{ qr.used }}
							</div>
							<div class="admin-item-col smaller">
								{{ date(qr.createdAt) }} <br /> {{ time(qr.createdAt) }}
							</div>
							<div class="admin-item-col smaller">
								{{ qr.expiresIn }} <!-- {{ date(qr.expiresIn)}}  {{time(qr.expiresIn) }} -->
							</div>
							<div class="admin-item-col large">
								{{ qr.qrCode }}
							</div>
						</div>
					</section>
				</div>

				<!-- QR Code logs -->
				<h3 class="admin-parking-info-header-title" style="margin-top: 30px;">QR Codes Logs</h3>
				<div class="qr-codes">
					<section v-if="sessionDetail.qrScanLogs && sessionDetail.qrScanLogs.length > 0">
						<div class="admin-item-row" style="border-color: black">
							<div class="admin-item-col smaller header">ID</div>
							<div class="admin-item-col smaller header">QR Code ID</div>
							<div class="admin-item-col smaller header">status</div>
							<div class="admin-item-col smaller header">Scanned At</div>
							<div class="admin-item-col medium header">Description</div>
							<div class="admin-item-col medium header">QR Code</div>
							<div class="admin-item-col medium header">Scanner Data</div>
						</div>
						<div v-for="(logs, index) in sessionDetail.qrScanLogs" :key="index"
							class="admin-item-row clickable">
							<div class="admin-item-col smaller">
								{{ parseInt(logs.id) }}
							</div>
							<div class="admin-item-col smaller">
								{{ logs.sessionQrCodeId }}
							</div>
							<div class="admin-item-col smaller">
								{{ logs.status ? 'Success' : 'Failed' }}
							</div>
							<div class="admin-item-col smaller">
								{{ date(logs.scannedAt) }}<br /> {{ time(logs.scannedAt) }}
							</div>
							<div class="admin-item-col medium" :title="logs.description">
								{{ logs.description }}
							</div>
							<div class="admin-item-col medium" :title="logs.payload">
								{{ logs.payload }}
							</div>
							<div class="admin-item-col medium" :title="JSON.stringify(logs.scannerPayload)">
								{{ logs.scannerPayload }}
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { date, time, diffInTime, tryString } from '@/helpers'
import session from '../../../store/modules/session';
import {config} from '../../../config.js';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	name: 'SessionDetail',
	components: {},
	props: {
		session: {
			type: Object,
			default: () => ({}),
		},
		cancelSession: {
			type: Function,
		},
		onSessionClose: {
			type: Function,
		},
		close: {
			type: Function,
		},
	},
	data() {
		return {
			invoiceUrl: `${config.apiUrl}/invoice/session/generatePDF`,
		}
	},

	computed: {
		...mapState({
			serverTime: (state) => state.serverTime,
			sessionDetail: (state) => state.admin.sessions.selected,
		}),
	},
	watch: {
		session(newVal) {
			console.log('watch-session', newVal.id)
			if (newVal && newVal.id) {
				this.setSessionSelected(newVal)
				this.getSessionById(newVal.id)
			}
		},
	},
	mounted() {
		if (this.session && this.session.id) {
			console.log('mounted-session', this.session.id, config.apiUrl)
			this.setSessionSelected(this.session)
			this.getSessionById(this.session.id)
		}
	},
	methods: {
		...mapActions('admin', {
			getSessionById: 'sessions/getSessionById',
			resendPaymentLinkSession: 'sessions/resendPaymentLinkSession',
		}),
		...mapMutations('admin', {
			setSessionSelected: 'sessions/setSessionSelected',
		}),
		date,
		time,
		diffInTime,
		tryString,
		getDuration(sessionData) {
			const entryQr = sessionData.sessionQRCodes.find(qr => qr.qrType == 'ENT')
			if (entryQr && entryQr.used == entryQr.usage) {
				return this.diffInTime(sessionData.startTime, (sessionData.endTime || this.serverTime))
			}
			return this.diffInTime(sessionData.startTime, this.serverTime)
		},
		structuredAddress(address) {
			return `${address.streetName} ${address.houseNumber} ${tryString(
				address.busNumber
			)} ${tryString(address.postalCode, { prefix: ', ' })} ${tryString(address.city, {
				prefix: address.postalCode ? '' : ', ',
			})}`
		},

	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';

.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
}

.qr-codes {
	.admin-item-col {
		line-height: 25px;
		overflow-wrap: break-word;
		line-break: anywhere;

		&.header {
			line-break: auto;
			line-height: 35px;
		}

		&.tiny {
			max-width: 60px;
		}
	}
}
</style>
