// session.js

import graphqlClient from '../../../api/db'
import gql from 'graphql-tag'
import { extractGraphqlError } from '@/helpers'
import { logger } from '../../../logger'
import i18n from '../../../i18nVeeValidate'

// Initial state
const initialState = () => ({
	all: [],
	total: 0,
	selected: {},
	isLoading: false,
	error: null,
})

const state = initialState()

// GraphQL Queries
const getAllSessionsQuery = gql`
	query AllSessions($limit: Int, $offset: Int, $isActive: Boolean, $search: String) {
		allSessions(limit: $limit, offset: $offset, isActive: $isActive, search: $search) {
			sessions {
				bikes
				duration
				endTime
				createdAt
				id
				active
				parking {
					code
					id
				}
				paymentMethod
				sessionId
				startTime
				canceledAt
				user {
					email
					firstName
					id
					phoneNumber
					lastName
					language
					addresses {
						id
						addressType
						streetName
						houseNumber
						busNumber
						city
						postalCode
						realAddress
					}
					legacyAddress
				}
				totalAmountPaid
				sessionQRCodes {
					id
					expiresIn
					qrCode
					qrType
					usage
					used
					usedAt
					userSessionId
					createdAt
				}
			}
			total
		}
		serverTime
	}
`

const getSessionByIdQuery = gql`
	query SessionById($id: Int) {
		sessionById(id: $id) {
				bikes
				duration
				endTime
				createdAt
				id
				active
				parking {
					code
					id
				}
				paymentMethod
				sessionId
				startTime
				canceledAt
				closedAt
				userId
				user {
					email
					firstName
					id
					phoneNumber
					lastName
					language
					mobib
					bankAccount
					addresses {
						id
						addressType
						streetName
						houseNumber
						busNumber
						city
						postalCode
						realAddress
					}
					legacyAddress
				}
				totalAmountPaid
				sessionQRCodes {
					id
					expiresIn
					qrCode
					qrType
					usage
					used
					usedAt
					userSessionId
					createdAt
				}
				qrScanLogs{
					id
					sessionQrCodeId
					status
					description
					payload
					scannedAt
					createdAt
					scannerPayload
				}
				sessionPayments{
					id
					sessionId
					hours
					amountPaid
					paidAt
					transactionId
					createdAt
				}
		}
		serverTime
	}
`

// GraphQL mutations
const closeSessionMutation = gql`
	mutation closeSession($sessionId: Int, $amount: Float) {
		closeSession(sessionId: $sessionId, amount: $amount) {
			sessionId
		}
	}
`

// Actions
const actions = {
	async getSessions({ commit }, { limit, offset, isActive, search }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: getAllSessionsQuery,
				variables: { limit, offset, isActive, search },
				fetchPolicy: 'no-cache',
			})
			const sessions = response.data.allSessions?.sessions
			const total = response.data.allSessions?.total

			commit('setSessionsTotal', total)
			commit('setSessions', sessions)
			commit('setServerTime', response.data.serverTime, { root: true })
		} catch (e) {
			logger.error('Problem retrieving sessions', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async getSessionById({ commit }, id) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: getSessionByIdQuery,
				variables: { id },
				fetchPolicy: 'no-cache',
			})
			const session = response.data.sessionById

			commit('setSessionSelected', session || {})
			commit('setServerTime', response.data.serverTime, { root: true })
		} catch (e) {
			logger.error('Problem retrieving sessions', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async closeSession({ commit }, {sessionId, amount}) {
		commit('setLoading', true, sessionId, amount)

		try {
			const response = await graphqlClient.mutate({
				mutation: closeSessionMutation,
				variables: { sessionId, amount: parseFloat(amount) },
			})
			return response.data.closelSession
		} catch (e) {
			logger.error('Problem closing session', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async resendPaymentLinkSession({ commit, dispatch }, paymentId) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
						mutation resendPaymentLinkSession($paymentId: Int!) {
							resendPaymentLinkSession(paymentId: $paymentId) 
						}
					`,
				variables: {
					paymentId,
				},
			})

			if (response.data.resendPaymentLinkSession) {
				dispatch('alert/success', i18n.t('Payment link email sent'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t(error.message), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},
}

// Mutations
const mutations = {
	resetState(state) {
		Object.assign(state, initialState())
	},
	setSessions(state, sessions) {
		state.all = sessions
	},
	setSessionsTotal(state, total) {
		state.total = total
	},
	setSessionSelected(state, session) {
		state.selected = session
	},
	setSessionId(state, sessionId) {
		localStorage.setItem('sessionId', sessionId)
		state.isLoggedIn = true
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setError(state, error) {
		state.error = error
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
