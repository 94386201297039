<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="whitelist" />
		<div class="admin-parking">
			<div>
				<div class="admin-parking-info-header">
					<h2 class="admin-parking-info-header-title">Search</h2>
					<form class="admin-parking-form admin-session-form" data-vv-scope="parking-search-form"
						autocomplete="off" @submit.prevent="searchForm()">
						<div class="admin-parking-search-content">
							<div class="admin-parking-search-field">
								<input ref="search" v-model="query" v-validate="{ required: true, min: 2 }"
									:placeholder="$t('ID, value')"
									:class="{ error: errors.has('parking-search-form.search') }" name="search"
									type="text" class="input-field admin-input-field input admin-search-field" />
								<p v-show="errors.has('parking-search-form.search')" class="input-error">
									{{ errors.first('parking-search-form.search') }}
								</p>
							</div>
							<button :disabled="errors.any('parking-search-form')" hidden
								:class="{ disabled: errors.any('parking-search-form') }"
								class="button blue admin-parking-search-button" type="submit">
								Search
							</button>
							<button class="button blue admin-parking-search-button" type="button" @click="toggleAddWhitelistItemView()">
								Add
							</button>
						</div>

					</form>
				</div>
				<div class="admin-parking-search">
					<section v-if="allWhitelist.length > 0">
						<div class="admin-item-row admin-parking-item-header" style="border-color: black">
							<div class="admin-item-col small header">ID</div>
							<div class="admin-item-col medium header">Value</div>
							<div class="admin-item-col small header">Action</div>
						</div>
						<WhitelistResult v-for="(item, index) in allWhitelist" :key="index" :whitelistItem="item"
							@on-click="viewSessionDetails($event)" :onRemove="removeWhitelistItem" />
					</section>
					<!-- <div class="paginator">
						<div class="left" @click="() => selectPage('prev')">
							<img src="../../assets/images/icon_arrow_left_dark_grey.svg" alt="Add Circle Icon"
								width="18" :class="{ disabled: selectedIndex == 0 }" />
						</div>
						<div class="page-selector" v-for="(paginationItem, index) in paginationList"
							:key="'pagination-item-' + index"
							:class="{ selected: selectedIndex + 1 == paginationItem.pageNumber }"
							@click="() => selectPage(paginationItem.pageNumber)">
							{{ paginationItem.pageNumber || '...' }}
						</div>
						<div class="right" @click="() => selectPage('next')">
							<img src="../../assets/images/icon_arrow_right_dark_grey.svg" alt="Add Circle Icon"
								width="18" :class="{ disabled: selectedIndex == allSessions.length - 1 }" />
						</div>
					</div> -->
				</div>
			</div>
		</div>

		<Modal v-if="addWhitelistForm.open" :modalOpen="addWhitelistForm.open" :cancelLabel="'Cancel'" :confirmLabel="'Add'"
			@confirm="addWhitelistItem" @cancel="toggleAddWhitelistItemView" @close="toggleAddWhitelistItemView">
			<template>
				<div>
					<h1 class="small-title center-div">
						Whitelist Item
					</h1>
					<div class="temporaryAccess-modal-parking-info">
						<p class="">
							Please enter email to whitelist
						</p>
						<input v-model="addWhitelistForm.email" type="email" class="input-field small" placeholder="example@domain.com"
							name="email" />
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import Modal from '@/components/Modal.vue'
import ValidationRequired from '@/mixins/validation-required'
import { filter, find } from 'lodash/fp'
import { getPaginationList } from '@/helpers/pagination'
import WhitelistResult from '../../components/admin/whitelist/WhitelistResult.vue'

/* import { logger } from '@/logger' */

export default {
	name: 'Whitelist',
	components: {
		Loading,
		Menu,
		WhitelistResult,
		Modal,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			query: '',
			filters: {
				type: 'email',
			},
			addWhitelistForm: { email: '', open: false },
			formOpen: false,
			customErrors: {},
			hasCustomError: false,
			selectedWhitelistItem: null,
			selectedIndex: 0,
			paginationList: [],
			perPageItems: 30,
		}
	},
	computed: {
		...mapState('admin', {
			allWhitelist: (state) => state.whitelist.all,
			totalWhitelist: (state) => state.whitelist.total,
			loading: (state) => (state.loading || state.whitelist.isLoading),
		}),
	},
	mounted() {
		this.$validator.pause()
		this.getWhitelistEmails({ limit: null, offset: null, ...this.filters })
	},
	methods: {
		...mapActions('admin', {
			getWhitelistEmails: 'whitelist/getWhitelistEmails',
			addWhitelistEmail: 'whitelist/addWhitelistEmail',
			removeWhitelistEmail: 'whitelist/removeWhitelistEmail',
		}),
		searchForm() {
			if (!this.query) {
				// this.filteredSessions = [...this.allSessions]
				this.getWhitelistEmails({ limit: this.perPageItems, offset: 0, ...this.filters })
			} else {
				this.getWhitelistEmails({ limit: this.perPageItems, offset: 0, ...this.filters, search: this.query })
			}
		},
		setFilter(key, value) {
			this.filters[key] = value
			this.getWhitelistEmails({ limit: this.perPageItems, offset: 0, ...this.filters, search: this.query })
		},
		removeWhitelistItem(email) {
			if (confirm('Are you sure you want to remove this whitelist item IMMEDIATELY?')) {
				return this.removeWhitelistEmail(email).then((response) => {
					if (response) {
						this.getWhitelistEmails({ limit: this.perPageItems, offset: 0, ...this.filters, search: this.query })
					}
				})
			}
			return false
		},
		async cancelNowSession(sessionId) {
			try {
				const response = await this.cancelCurrentSession(parseInt(sessionId))
				if (response) {
					this.getWhitelistEmails({ limit: 50, offset: 0, ...this.filters })
				}
			} catch (error) {
				console.error('Error fetching session:', error)
			}
		},
		viewSessionDetails(id) {
			// this.selectedSession = find({ id }, this.allSessions)
		},
		closeViewSessionDetails() {
			this.selectedSession = null
		},
		getPaginationList,
		selectPage(page) {
			switch (page) {
				case 'prev':
					this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.selectedIndex
					break
				case 'next':
					this.selectedIndex =
						this.selectedIndex < this.userSessions?.length - 1
							? this.selectedIndex + 1
							: this.selectedIndex
					break
				default:
					this.selectedIndex =
						page && page > 0 && page <= this.totalSessions ? page - 1 : this.selectedIndex
					break
			}
			this.getWhitelistEmails({ limit: this.perPageItems, offset: (this.perPageItems * (page - 1)), ...this.filters })
			this.setPaginationList()
		},
		setPaginationList() {
			const pages = Math.ceil(this.totalSessions / this.perPageItems)
			this.paginationList = this.getPaginationList(pages, this.selectedIndex + 1)
		},
		toggleAddWhitelistItemView() {
			if (!this.addWhitelistForm.open) {
				this.addWhitelistForm = { email: '', open: true }
			} else {
				this.addWhitelistForm = { email: '', open: false }
			}
		},
		async addWhitelistItem() {
			console.log('adding to whitelist..', this.addWhitelistForm.email)
			const response = await this.addWhitelistEmail(this.addWhitelistForm.email)
			if (response && response.id) {
				this.addWhitelistForm = { email: '', open: false }
				this.getWhitelistEmails({ limit: this.perPageItems, offset: (this.perPageItems * (page - 1)), ...this.filters, search: this.query })
			}
		}

	},
	watch: {
		allWhitelist(newVal) {
			if (newVal) {
				this.filteredSessions = newVal || []
				if (this.query) {
					// this.searchForm()
				}
				this.setPaginationList()
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/adminParking';
@import '../../assets/scss/pages/admin';

.mx-datepicker {
	width: 100%;
}

.mx-input-append {
	width: 35px;
	right: 5px;
}

.admin-session-form {
	width: 75%;
}

.admin-parking-search {
	margin-top: 10px;

	.admin-parking-item-header {
		padding-bottom: 8px;
	}
}

.tabs {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;

	.tab {
		font-size: 18px;
		font-weight: bold;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		color: dodgerblue;
		background: none;
		border: none;
		border-radius: 0px;
		border-bottom: 2px solid transparent;
		padding: 0 20px;
		cursor: pointer;

		&.active {
			color: dodgerblue;
			border-bottom: 2px solid dodgerblue;
		}
	}

}

.paginator {
	// background: #faf2d5;
	padding-top: 16px;
	display: flex;
	justify-content: center;

	.left {
		display: flex;
		cursor: pointer;
		margin-right: 8px;
	}

	.right {
		display: flex;
		cursor: pointer;
		margin-left: 8px;
	}

	.page-selector {
		cursor: pointer;
		padding: 0 2px;
		margin: 0 6px;

		&.selected {
			color: #e75131;
		}
	}

	.disabled {
		opacity: 0.3;
	}
}
</style>
