<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="export" />
		<div class="admin-export">
			<JsonExcel
				:fetch="doDepositReport"
				:fields="depositReportFields"
				:name="`deposit-report-${date(new Date().getTime())}.csv`"
				class="button blue"
				type="csv"
				:escapeCsv="false"
			>
				Deposit Report
			</JsonExcel>
			<br />
			<JsonExcel
				:fetch="doIncomeReport"
				:fields="incomeReportFields"
				:name="`income-report-${date(new Date().getTime())}.csv`"
				class="button blue"
				type="csv"
				:escapeCsv="false"
			>
				Income Report
			</JsonExcel>
			<br />
			<JsonExcel
				:fetch="doInventoryReport"
				:fields="inventoryReportFields"
				:name="`inventory-report-${date(new Date().getTime())}.csv`"
				class="button blue"
				type="csv"
				:escapeCsv="false"
			>
				Inventory Report
			</JsonExcel>
			<br />
			<JsonExcel
				:fetch="doProrationReport"
				:name="`proration-report-${date(reportParameterFields.from.getTime())}-${date(
					reportParameterFields.to.getTime()
				)}.csv`"
				class="button blue"
				type="csv"
				:escapeCsv="false"
			>
				Proration Report
			</JsonExcel>
			<ReportParameter v-model="reportParameterFields" />
			<br />
			<JsonExcel
				:fetch="doSessionReport"
				:fields="sessionReportFields"
				:name="`session-report-${date(reportParameterFields.from.getTime())}-${date(
					reportParameterFields.to.getTime()
				)}.csv`"
				class="button blue"
				type="csv"
				:escapeCsv="false"
			>
				Session Report
			</JsonExcel>
			<ReportParameter v-model="reportParameterFields" :showGroupBy="false" :showGroupOf="false" />
		</div>
		<div class="admin-export">
			<div class="flex">
				<JsonExcel
					:fetch="doParkingExport"
					:fields="parkingFields"
					:name="`parking-export-${date(new Date().getTime())}.csv`"
					class="button orange"
					type="csv"
					:escapeCsv="true"
				>
					Download parking data
				</JsonExcel>
				<JsonExcel
					:fetch="doParkingHeatmapExport"
					:fields="parkingHeatmapFields"
					:name="`heatmap-parking-export-${date(new Date().getTime())}.csv`"
					class="button orange"
					type="csv"
					:escapeCsv="true"
				>
					Download parking heatmap data
				</JsonExcel>
			</div>
			<br />
			<div class="flex">
				<JsonExcel
					:fetch="doRequestsExport"
					:fields="requestFields"
					:name="`requests-export-${date(new Date().getTime())}.csv`"
					class="button orange"
					type="csv"
					:escapeCsv="true"
				>
					Download Request data
				</JsonExcel>
				<JsonExcel
					:fetch="doRequestsExport"
					:fields="requestHeatmapFields"
					:name="`heatmap-requests-export-${date(new Date().getTime())}.csv`"
					class="button orange"
					type="csv"
					:escapeCsv="true"
				>
					Download Request Heatmap data
				</JsonExcel>
			</div>
			<br />
			<JsonExcel
				:fetch="doUsersExport"
				:fields="userFields"
				:name="`users-export-${date(new Date().getTime())}.csv`"
				class="button orange"
				type="csv"
				:escapeCsv="true"
			>
				Download Users data
			</JsonExcel>
			<br />
			<JsonExcel
				:fetch="doSubscriptionExport"
				:fields="subscriptionFields"
				:name="`subscriptions-export-${date(new Date().getTime())}.csv`"
				class="button orange"
				type="csv"
				:escapeCsv="false"
			>
				Download Subscription data
			</JsonExcel>
			<br />

			<JsonExcel
				:fetch="doProposalExport"
				:fields="proposalFields"
				:name="`proposals-export-${date(new Date().getTime())}.csv`"
				class="button orange"
				type="csv"
				:escapeCsv="false"
			>
				Download Proposal data
			</JsonExcel>
			<br />

			<JsonExcel
				:fetch="doInvoiceItemExport"
				:fields="invoiceItemFields"
				:name="`invoiceItems-export-${date(new Date().getTime())}.csv`"
				class="button orange"
				type="csv"
				:escapeCsv="false"
			>
				Download Invoice data
			</JsonExcel>
			<br />

			<JsonExcel
				:fetch="doAuditTrailExport"
				:fields="auditTrailFields"
				:name="`auditTrail-export-${date(new Date().getTime())}.csv`"
				class="button orange"
				type="csv"
				:escapeCsv="false"
			>
				Download Audit trail data (last 6 months)
			</JsonExcel>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import ReportParameter from '@/components/admin/ReportParameter'
import JsonExcel from 'vue-json-excel'
import { date } from '@/helpers'
import {
	requestFields,
	userFields,
	subscriptionFields,
	proposalFields,
	invoiceItemFields,
	parkingFields,
	parkingHeatmapFields,
	requestHeatmapFields,
	auditTrailFields,
} from '@/store/modules/admin/exports'
import {
	depositReportFields,
	inventoryReportFields,
	incomeReportFields,
	sessionReportFields,
} from '@/store/modules/admin/reports'
import ValidationRequired from '@/mixins/validation-required'

export default {
	name: 'AdminExport',
	components: {
		Loading,
		Menu,
		JsonExcel,
		ReportParameter,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			requestFields,
			userFields,
			subscriptionFields,
			proposalFields,
			invoiceItemFields,
			reportParameterFields: this.defaultReportParametersFields(),
			depositReportFields,
			incomeReportFields,
			inventoryReportFields,
			parkingFields,
			parkingHeatmapFields,
			requestHeatmapFields,
			auditTrailFields,
			sessionReportFields,
		}
	},
	computed: {
		...mapState('admin', {
			loading: (state) => state.loading,
			dataExport: (state) => state.exports.data,
		}),
	},
	mounted() {},
	methods: {
		...mapActions('admin', {
			exportParkings: 'exportParkings',

			// exportHeatmapParkings: 'exportHeatmapParkings',
			exportRequests: 'exportRequests',
			exportUsers: 'exportUsers',
			exportSubscriptions: 'exportSubscriptions',
			exportProposals: 'exportProposals',
			exportInvoiceItems: 'exportInvoiceItems',
			exportAuditTrails: 'exportAuditTrails',

			reportDeposit: 'reportDeposit',
			reportIncome: 'reportIncome',
			reportInventory: 'reportInventory',
			reportProration: 'reportProration',
			reportSession: 'reportSession',
		}),

		async doParkingExport() {
			await this.exportParkings()

			return this.dataExport
		},

		async doParkingHeatmapExport() {
			await this.exportParkings()

			return this.dataExport
		},
		async doUsersExport() {
			await this.exportUsers()

			return this.dataExport
		},
		async doRequestsExport() {
			await this.exportRequests()

			return this.dataExport
		},
		async doSubscriptionExport() {
			await this.exportSubscriptions()

			return this.dataExport
		},
		async doProposalExport() {
			await this.exportProposals()

			return this.dataExport
		},

		async doAuditTrailExport() {
			await this.exportAuditTrails()

			return this.dataExport
		},

		async doInvoiceItemExport() {
			await this.exportInvoiceItems()

			return this.dataExport
		},

		async doDepositReport() {
			await this.reportDeposit()

			return this.dataExport
		},
		async doIncomeReport() {
			await this.reportIncome()

			return this.dataExport
		},
		async doInventoryReport() {
			await this.reportInventory()

			return this.dataExport
		},
		async doProrationReport() {
			await this.reportProration(this.reportParameterFields)

			return this.dataExport
		},
		async doSessionReport() {
			await this.reportSession(this.reportParameterFields)
			return this.dataExport
		},

		defaultReportParametersFields() {
			const now = new Date()
			const from = new Date(now.getFullYear(), 0, 1)
			const to = new Date(now.getFullYear() + 1, 0, 1)

			return {
				from,
				to,
				groupBy: 'period',
				groupOf: 3,
			}
		},

		prorationReportFileName() {
			return `proration-report-${date(this.reportParameterFields.from.getTime())}-${date(
				this.reportParameterFields.to.getTime()
			)}.csv`
		},

		date,
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/adminExport';
@import '../../assets/scss/pages/admin';
.mx-datepicker {
	width: 100%;
}
.mx-input-append {
	width: 35px;
	right: 5px;
}
.flex {
	display: flex;
	.button {
		margin-right: 20px;
	}
}
</style>
