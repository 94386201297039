<template>
	<div class="admin-item-row clickable sessions-row">
		<div class="admin-item-col small" @click="$emit('on-click', session.id)">
			{{ parseInt(session.sessionId) }}
		</div>
		<div class="admin-item-col medium" @click="$emit('on-click', session.id)">
			<span v-if="session.user">{{ session.user.firstName }} {{ session.user.lastName }}</span>
		</div>
		<div class="admin-item-col small" @click="$emit('on-click', session.id)">
			{{ session.bikes || 0 }}
		</div>
		<div class="admin-item-col small" @click="$emit('on-click', session.id)">
			{{ session.parking ? session.parking.code : '-' }}
		</div>
		<div class="admin-item-col small" @click="$emit('on-click', session.id)">
			{{ time(session.startTime) }}
		</div>
		<div class="admin-item-col small" @click="$emit('on-click', session.id)">
			{{ getDuration(session) }}
			<!-- {{ diffInTime(session.startTime, session.endTime || new Date().getTime()) }} -->
		</div>
		<div class="admin-item-col medium actions">
			<button v-if="session.active" class="button red" @click="cancelSession(session.id)">Cancel</button>
			<!-- <button v-if="session.active" class="button red" @click="onSessionClose(session.id)" title="Close Session">Close</button> -->
		</div>
	</div>
</template>

<script>
import { time, diffInTime } from '@/helpers'
import session from '../../../store/modules/session';
import { mapState } from 'vuex';
export default {
	name: 'SessionResult',
	components: {},
	props: {
		session: {
			type: Object,
			default: () => ({}),
		},
		cancelSession: {
			type: Function,
		},
		onSessionClose: {
			type: Function,
		},
	},
	data() {
		return {}
	},

	computed: {
		...mapState({
			serverTime: (state) => state.serverTime,
		}),
	},
	methods: {
		time,
		diffInTime,
		getDuration(sessionData) {
			const entryQr = sessionData.sessionQRCodes.find(qr => qr.qrType == 'ENT')
			if (entryQr && entryQr.used == entryQr.usage) {
				return this.diffInTime(sessionData.startTime, (sessionData.endTime || this.serverTime))
			}
			return this.diffInTime(sessionData.startTime, this.serverTime)
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
	&.sessions-row{
		.button{
			line-height: 34px;
		}
	}
}

.admin-item-row {
	&.sessions-row {
		.button {
			line-height: 34px;
		}
	}
}
</style>
