<template>
	<div class="connection-status-wrapper">
		<div :class="['connection-status', { online: isOnline, offline: !isOnline }]">
			<span :class="['indicator', { online: isOnline, offline: !isOnline }]"></span>
			{{ isOnline ? $t('sessionParking.stable') : $t('sessionParking.unstable') }}
		</div>
	</div>
</template>

<script>
import { gql } from 'graphql-tag';
import graphqlClient from '../api/db'

export default {
	name: 'ConnectionStatus',
	props: {
		getSessionById: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			isOnline: navigator.onLine,
		};
	},
	created() {
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		this.checkInternetConnection();
		this.interval = setInterval(this.checkInternetConnection, 2000); // Check every 2 seconds
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
		clearInterval(this.interval);
	},
	methods: {
		updateOnlineStatus() {
			const wasOffline = !this.isOnline;
			this.isOnline = navigator.onLine;
			if (this.isOnline && wasOffline) {
				this.getSessionById();
			}
		},
		async checkInternetConnection() {
			const controller = new AbortController();
			const timeout = setTimeout(() => controller.abort(), 1000); // 1000ms timeout

			try {
				await graphqlClient.query({
					query: gql`
						query {
							serverTime
						}
					`,
					fetchPolicy: 'no-cache',
					context: {
						fetchOptions: {
							signal: controller.signal,
						},
					},
				});
				this.isOnline = true;
			} catch (error) {
				this.isOnline = false;
			} finally {
				clearTimeout(timeout);
			}
		},
	},
};
</script>

<style scoped>
.connection-status-wrapper {
	width: 100%;
	background-color: transparent;
	display: flex;
	justify-content: flex-end;
}

.connection-status {
	display: flex;
	align-items: center;
	padding: 5px 10px;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	font-size: 12px;
	color: #333;
	margin-right: 20px;
	transition: border-color 0.5s;
}
.connection-status .indicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
}
.connection-status.online {
	display: none;
	border-color: #28a745; /* Green */
}
.connection-status.online .indicator {
	display: none;
	background-color: #28a745; /* Green */
}
.connection-status.offline {
	border-color: #dc3545; /* Red */
}
.connection-status.offline .indicator {
	background-color: #dc3545; /* Red */
	animation: blink 1s infinite;
}

@keyframes blink {
	0%, 100% { opacity: 1; }
	50% { opacity: 0; }
}
</style>
