<template>
	<div>
		<Loading v-if="!isLoaded || isLoading" />
		<form v-if="!showPhoneForm" class="profile-form" data-vv-scope="request-form" @submit.prevent="">
			<div class="input-box profile-email-input-box">
				<label class="profile-input-field-label">
					{{ $t('signup.telephoneLabel') }}
				</label>
				<!-- :placeholder="$t('profile.phoneLabel')" -->
				<vue-tel-input
					v-model="phoneNumber"
					v-validate="'numeric'"
					:preferredCountries="['be', 'nl', 'fr']"
					:class="{ error: !!localErrors.phone }"
					:inputOptions="localizedData.options"
					name="phone"
					class="sms-validation-input-field input-phone-box profile-input-field"
					@input="onInputPhone"
				/>
				<p v-show="localErrors.phone" class="input-error">
					{{ localErrors.phone }}
				</p>
			</div>
			<div
				v-if="currentUser.status && currentUser.status !== 0"
				<div
				class="input-box profile-password-input-box"
			>
			<label class="profile-input-field-label">{{ $t('profile.confirmPassword') }}</label>
				<input
					ref="passwordConfirmation"
					v-model="passwordConfirmation.value"
					:placeholder="$t('profile.confirmPassword')"
					name="password"
					type="password"
					class="input-field profile-input-field"
				/>
				<div
					class="input-reveal-button with-label"
					tabindex="0"
					@click="doToggleReveal('passwordConfirmation')"
					@keyup.enter="doToggleReveal('passwordConfirmation')"
				>
					<div
						:class="{ revealed: passwordConfirmation.reveal }"
						class="input-reveal-button-line"
					/>
				</div>
			</div>
			<button
				:disabled="hasLocalErrors()"
				:class="{ disabled: hasLocalErrors() }"
				class="button red"
				@click="doRequestToken()"
			>
				{{ $t('smsValidation.requestTokenLabel') }}
			</button>
		</form>

		<form v-if="showPhoneForm" v-show="currentUser.verificationId || currentUser.hasVerificationId" class="sms-validation-form"
			data-vv-scope="code-form" @submit.prevent="">
			<p class="text sms-validation-info">
				{{ $t('emailValidation.requestInfo') + ' [' + currentUser.phoneNumberTemp + ']' }}
			</p>
			<div class="input-box sms-validation-otp-inputs-sms">
				<input v-for="(input, index) in inputs" :key="index" v-model="input.value"
					@input="handleInput(index, $event)" @keyup="handleKeyUp(index, $event)" maxlength="1" ref="inputs"
					:class="{ 'last-input': index === inputs.length - 1, error: input.error }"
					class="sms-validation-otp-input-field input-field" type="tel" inputmode="numeric"
					pattern="[0-9]*" />
				<p v-if="errors.has('code-form.accessCode')" class="input-error">
					{{ errors.first('code-form.accessCode') }}
				</p>
			</div>
			<div>
				<span class="sms-validation-link-button-link" @click="doRequestToken()">
					{{ $t('smsValidation.resendCode') }}
				</span>
			</div>
			<button :disabled="errors.has('code-form.accessCode') || smsCode.length < 4"
				:class="{ disabled: errors.has('code-form.accessCode') || smsCode.length < 4 }"
				class="button red sms-validation-button-confirm" @click="doValidateToken">
				{{ $t('smsValidation.validateLabel') }}
			</button>
		</form>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import { toggleReveal } from '@/helpers'
import ValidationRequired from '@/mixins/validation-required'

export default {
	name: 'SmsValidation',
	components: {
		Loading,
	},
	mixins: [ValidationRequired],
	props: {
		verification: {
			default: true,
			type: Boolean,
		},
		from: {
			String: true,
		},
	},
	data() {
		return {
			loading: false,
			smsCode: '',
			inputs: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
			phoneNumber: '',
			localErrors: {
				phone: '',
			},
			local: {
				phoneNumber: '',
			},
			passwordConfirmation: {
				value: '',
				reveal: false,
			},
			telDoptions: { showDialCodeInSelection: true, showFlags: true },
			telIoptions: { showDialCode: true },
			open: false,
			options: {
				placeholder: this.$t('profile.phoneLabel'),
			},
			// localUser: Object.assign({}, this.currentUser),
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.authentication.isLoading,
			alert: (state) => state.alert,
			// showTelForm: (state) => state.authentication.showTelForm,
			showPhoneForm: (state) => state.authentication.showPhoneForm,
		}),
		...mapGetters({
			isLoaded: 'profile/isLoaded',
		}),
		localizedData() {
			return {
				options: { placeholder: this.$t('profile.phoneLabel') },
			}
		},
		localUser() {
			return Object.assign({}, this.currentUser)
		},
	},
	created() {
		if (!this.isLoaded) {
			this.loadCurrentUser().then(() => this.setLocalPhone())
		} else {
			this.setLocalPhone()
		}
	},
	methods: {
		...mapActions({
			loadCurrentUser: 'profile/getCurrentUser',
			validateToken: 'authentication/validateToken',
			requestToken: 'authentication/requestToken',
		}),
		async doValidateToken() {
			await this.validateToken(this.smsCode)

			// this.flash(this.alert.message, this.alert.type)
		},
		async doRequestToken() {
			// We store the phoneNumber in a local val on the 'onInputPhone' event because of a bug with the vue-phone-number-input component
			// When we copy-past a phone number in the field when it was already filled-in the country-code was not added.
			// This way, we make sure that the phone number is always correct.
			this.phoneNumber = this.local.phoneNumber

			await this.requestToken({
				phone: this.phoneNumber,
				password: this.passwordConfirmation.value,
			})

			// this.flash(this.alert.message, this.alert.type)
		},
		hasLocalErrors() {
			return Boolean(this.localErrors.phone) || (this.phoneNumber || '').length < 1
		},
		onInputPhone(value, phoneObject) {
			this.local.phoneNumber = phoneObject.number

			const empty = (value || '').length < 1

			this.localErrors.phone = empty || phoneObject.valid ? '' : this.$t('error.notValidPhone')
		},

		setLocalPhone() {
			this.phoneNumber = this.currentUser.phoneNumber || this.currentUser.phoneNumberTemp || ''
		},
		doShowTelForm() {
			this.$store.commit('authentication/setShowTelForm', true)
		},
		doToggleReveal(field) {
			this[field].reveal = toggleReveal(this[field], this.$refs[field])
		},
		handleInput(index, event) {
			const val = event.target.value
			if (isNaN(val)) {
				this.inputs[index].value = ''
				return
			}
			if (val !== '') {
				if (this.smsCode.length < 4) {
					// this.smsCode += String(val)
					this.smsCode = this.inputs.map((input) => input.value).join('')
					// console.log('this.smsCode', this.smsCode)
				}
				const nextIndex = index + 1
				if (this.inputs[nextIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[nextIndex].focus()
					})
				}
			}
		},
		handleKeyUp(index, event) {
			const key = event.key.toLowerCase()
			if (key === 'backspace' || key === 'delete') {
				this.inputs[index].value = ''
				if (this.smsCode.length > 0) {
					this.smsCode = this.smsCode.slice(0, -1)
				}
				const prevIndex = index - 1
				if (this.inputs[prevIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[prevIndex].focus()
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/smsValidation';
@import '../../assets/scss/pages/profile/index';
</style>
