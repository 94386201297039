var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-blue"},[(!_vm.sessionEnd || _vm.isLoading)?_c('Loading'):_vm._e(),(_vm.sessionEnd && !_vm.isLoading)?_c('div',{staticClass:"start-session-qr"},[_c('ConnectionStatus',{attrs:{"getSessionById":_vm.loadSessionPeriodically}}),_c('GenrateQr',{attrs:{"type":_vm.localizedData.scanType,"title":_vm.localizedData.title,"description":_vm.localizedData.description,"sessionId":_vm.sessionEnd ? _vm.sessionEnd.sessionId : '0',"qrCodeText":_vm.sessionEnd && _vm.sessionEnd.sessionQRCodes.length > 0
					? _vm.sessionEnd.sessionQRCodes[0].qrCode
					: 'scan to encode',"numOfScan":_vm.sessionEnd
					? _vm.sessionEnd.sessionQRCodes.length > 0
						? _vm.sessionEnd.sessionQRCodes[0].used
						: 0
					: 0,"totalScan":_vm.sessionEnd
					? _vm.sessionEnd.sessionQRCodes.length > 0
						? _vm.sessionEnd.sessionQRCodes[0].usage
						: 0
					: 0,"message":_vm.localizedData.message}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }