<template>
	<div class="bg-blue">
		<Loading v-if="!sessionStop || isLoading" />
		<div v-if="sessionStop && !isLoading" class="start-session-qr">
		<ConnectionStatus :getSessionById="loadSessionPeriodically" />
			<GenerateQr
				:type="localizedData.scanType"
				:title="localizedData.title"
				:description="localizedData.description"
				:sessionId="sessionStop ? sessionStop.sessionId : '0'"
				:qrCodeText="
					sessionStop && sessionStop.sessionQRCodes.length > 0
						? sessionStop.sessionQRCodes[sessionStop.sessionQRCodes.length - 1].qrCode
						: 'scan to encode'
				"
				:numOfScan="
					sessionStop
						? sessionStop.sessionQRCodes.length > 0
							? sessionStop.sessionQRCodes[sessionStop.sessionQRCodes.length - 1].used
							: 0
						: 0
				"
				:totalScan="
					sessionStop
						? sessionStop.sessionQRCodes.length > 0
							? sessionStop.sessionQRCodes[sessionStop.sessionQRCodes.length - 1].usage
							: 0
						: 0
				"
				:prevNumOfScan="prevNumOfScan"
				:sessionQrData="
					sessionStop
						? sessionStop.sessionQRCodes.length > 0
							? sessionStop.sessionQRCodes
							: []
						: []
				"
				:message="localizedData.message"
				:timeLimit="
					sessionStop
						? sessionStop.sessionQRCodes.length > 0
							? sessionStop.sessionQRCodes[sessionStop.sessionQRCodes.length - 1].expiresIn
							: 5
						: 5
				"
				:startTime="
					sessionStop ? sessionStop.sessionQRCodes[sessionStop.sessionQRCodes.length - 1].usedAt : 0
				"
				:endSession="openModal"
			/>
		</div>

		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template v-slot:title>
				<h2>{{ modalTitle }}</h2>
			</template>
			<template v-slot:content>
				<p>
					{{ modalDescription }}
				</p>
				<div>
					<div class="alert-message">
						<img
							src="../../assets//images/session//icon_warning_black.svg"
							alt="Add Circle Icon"
							class="icon-svg alert-img"
						/>
						<span class="alert">{{ modalAlertText }}</span>
					</div>
				</div>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction">Recuperer mon velo</button>
			</template>
			<template v-slot:cancelButton>
				<a class="cancel" @click="closeModal">Annuler</a>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import GenerateQr from '@/components/session/GenerateQr.vue'
import SessionRequired from '@/mixins/session-required'
import SessionModal from '@/components/SessionModal.vue'
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import io from 'socket.io-client'
import { config } from '@/config'
import ConnectionStatus from '@/components/ConnectionStatus.vue'

export default {
	name: 'EnteringQr',
	components: {
		GenerateQr,
		Loading,
		SessionModal,
		ConnectionStatus,
	},
	mixins: [SessionRequired],
	data() {
		return {
			qrScannedStatus: null,
			isLoading: false,
			showModal: false,
			modalTitle: 'Confirmation Modal',
			modalDescription:
				'Lorsque vous souhaitez terminer votre session, cliquez sur le bouton « terminer la session ». Vous aurez deux CODE QR, un premier pour entrez et un second pour sortir ',
			modalAlertText: 'Are you sure you to proceed this action',
			mainDoorPickupQrTime: null,
		}
	},
	computed: {
		localizedData() {
			return {
				scanType: 'entering-qr',
				title: this.$t('sessionParking.qrCode2.title'),
				description: this.$t('sessionParking.qrCode2.description'),
				message: this.$t('sessionParking.qrCode2.message'),
			}
		},
		...mapState({
			sessionStop: (state) =>
				state.session.sessionStop.length > 0 ? state.session.sessionStop[0] : null,
		}),
		prevNumOfScan() {
			if (!this.sessionStop || !this.sessionStop.sessionQRCodes) {
				return 0
			}
			const filteredRecords = this.sessionStop.sessionQRCodes
			return filteredRecords.filter((record) => record.used > 0).length
		},
	},
	methods: {
		...mapActions('session', ['getSessionById']),
		...mapActions('session', ['generateQrCode']),
		...mapActions('session', ['updateQrCodeUsage']),
		...mapActions('alert', ['error']),

		async loadSessionPeriodically() {
			const sessionId = localStorage.getItem('sessionId')

			if (sessionId) {
				const numericSessionId = parseInt(sessionId, 10)

				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						sessionId: numericSessionId,
					}
					try {
						await this.getSessionById(sessionProps)
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},

		openModal() {
			// Show the default modal
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		},
		async endSession() {
			const sessionId = localStorage.getItem('sessionId')

			if (sessionId) {
				const numericSessionId = parseInt(sessionId, 10)

				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						rfid: this.sessionStop.sessionQRCodes[this.sessionStop.sessionQRCodes.length - 1]
							.qrCode,
						parkingId: this.sessionStop.parkingId,
					}
					try {
						await this.updateQrCodeUsage(sessionProps)
						this.loadSessionPeriodically()
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},
		async confirmAction() {
			this.endSession()
			this.closeModal()
		},
	},
	created() {
		// Connect to the Socket.IO server
		this.socket = io(config.apiUrl, { transports: ['websocket'] }) // Replace with your server URL
		// Handle event from the server for Subscription first charge
		this.socket.on('QrScanned', ({ response }) => {
			this.qrScannedStatus = response
			if (this.qrScannedStatus) {
				if (this.qrScannedStatus.success === true) {
					const rfId = this.qrScannedStatus.rfid
					if (
						rfId ===
						this.sessionStop.sessionQRCodes[this.sessionStop.sessionQRCodes.length - 1].qrCode
					) {
						this.isLoading = true
						setTimeout(() => {
							this.isLoading = false
						}, 5000)
						this.loadSessionPeriodically()
					}
				} else {
					if (this.qrScannedStatus.errorCode) {
						this.error(this.$t(`error.qrScan.${this.qrScannedStatus.errorCode}`))
					} else {
						this.error(this.qrScannedStatus.message)
					}
				}
			}
		})

		// Example: handling connection error
		this.socket.on('connect_error', (error) => {
			console.error('Connection error:', error)
		})
	},
	beforeDestroy() {
		this.socket.off('QrScanned')
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
</style>
